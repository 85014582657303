<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-row>
      <v-col />
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-1 "
      stle
      :loading-text="$t('table.messages.loading')"
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 100],
        showFirstLastPage: false,
        'items-per-page-text': $t('table.messages.items_per_page'),
      }"
      :search="filterSearch"
      :custom-filter="filterByError"
    >
      <template slot="no-data">
        {{ $t('table.messages.no_data') }}
      </template>

      <template v-slot:footer.page-text="props">
        {{ props.pageStart }} - {{ props.pageStop }}
        {{ $t('table.headers.page-text') }}
        {{ props.itemsLength }}
      </template>
      <template v-slot:top>
        <v-container class="mt-5">
          <v-row>
            <v-col class="pb-0">
              <div>
                <span class="forth--text text-h4 font-weight-bold ">{{
                  $t('messages.browse_file')
                }}</span>
              </div>
            </v-col>
          </v-row>
          <v-row class="d-flex ">
            <v-col cols="10">
              <div class="align-self-center mr-9">
                <v-file-input
                  v-model="file"
                  :loading="loading"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  counter
                  show-size
                  truncate-length="29"
                  outlined
                  prepend-icon=""
                >
                  <template v-slot:counter="props">
                    <div>
                      {{
                        props.props.value.match(/\d+\.*\d*/g)[0] +
                          'ファイル （合計' +
                          props.props.value.match(/\d+\.*\d*/g)[1] +
                          'B）'
                      }}
                    </div>
                  </template>
                </v-file-input>
              </div>
            </v-col>

            <v-col
              cols="2"
              class="d-flex align-end p-0"
            >
              <div class=" mb-9">
                <v-btn
                  color="primary"
                  large
                  @click="$router.go(-1)"
                >
                  <span class="pr-5 pl-5 font-weight-bold text-h5">
                    {{ $t('table.messages.back') }}</span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row class="d-flex ">
            <v-col cols="3">
              <v-select
                v-model="filterSearch"
                :items="previewOptions"
                :label="$t('messages.preview_option')"
                item-text="text"
                item-value="value"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:item.index="props">
        {{ props.index + 1 }}
      </template>
      <template v-slot:item.name="props">
        <span
          style="height: 100%"
          :class="setHighlight(props.item, 'name')"
        >{{
          props.item.name
        }}</span>
      </template>
      <template v-slot:item.manageId="props">
        <span
          style="height: 100%"
          :class="setHighlight(props.item, 'manageId')"
        >{{ props.item.manageId }}</span>
      </template>
      <template v-slot:item.sankyouKumamotoNum="props">
        <span
          style="height: 100%"
          :class="setHighlight(props.item, 'sankyouKumamotoNum')"
        >{{ props.item.sankyouKumamotoNum }}</span>
      </template>
      <template v-slot:item.serviceInfo="props">
        <span
          style="height: 100%"
          :class="setHighlight(props.item, 'serviceInfo')"
        >{{ props.item.serviceInfo }}</span>
      </template>
      <template v-slot:item.dob="props">
        <span
          style="height: 100%"
          :class="setHighlight(props.item, 'dob')"
        >{{
          props.item.dob
        }}</span>
      </template>
      <template v-slot:item.furiganaName="props">
        <span
          style="height: 100%"
          :class="setHighlight(props.item, 'furiganaName')"
        >{{ props.item.furiganaName }}</span>
      </template>
      <template v-slot:item.contactPhone="props">
        <span
          style="height: 100%"
          :class="setHighlight(props.item, 'contactPhone')"
        >{{ props.item.contactPhone }}</span>
      </template>
      <template v-slot:item.age="props">
        <span
          style="height: 100%"
          :class="setHighlight(props.item, 'age')"
        >{{
          getAge(props.item.dob)
        }}</span>
      </template>
      <template v-slot:item.sex="props">
        <span
          style="height: 100%"
          :class="setHighlight(props.item, 'sex')"
        >{{
          props.item.sex === 'M'
            ? $t('messages.male')
            : props.item.sex === 'F'
              ? $t('messages.female')
              : ''
        }}</span>
      </template>
      <template v-slot:item.language="props">
        <span
          style="height: 100%"
          :class="setHighlight(props.item, 'language')"
        >{{ props.item.language }}</span>
      </template>
      <template v-slot:item.collegeName="props">
        <span
          style="height: 100%"
          :class="setHighlight(props.item, 'collegeName')"
        >{{ props.item.collegeName }}</span>
      </template>
      <template v-slot:item.campusCardValidDate="props">
        <span
          style="height: 100%"
          :class="setHighlight(props.item, 'campusCardValidDate')"
        >{{ props.item.campusCardValidDate }}</span>
      </template>
      <template v-slot:item.residenceCardId="props">
        <span
          style="height: 100%"
          :class="setHighlight(props.item, 'residenceCardId')"
        >{{ props.item.residenceCardId }}</span>
      </template>
      <template v-slot:item.residenceCardValidDate="props">
        <span
          style="height: 100%"
          :class="setHighlight(props.item, 'residenceCardValidDate')"
        >{{ props.item.residenceCardValidDate }}</span>
      </template>
      <template v-slot:item.residenceCardValidDateLeft="props">
        <span>{{ residenceCardValidDateLeft(props.item) }}</span>
      </template>
      <template v-slot:item.studentCardValidDateLeft="props">
        <span>{{ studentCardValidDateLeft(props.item) }}</span>
      </template>
      <template v-slot:footer.prepend />
    </v-data-table>
    <v-row class="d-flex justify-end mt-3">
      <v-col
        cols="3"
        class="d-flex justify-space-between"
      >
        <v-btn
          large
          color="info"
        >
          <span
            class="text-h5 pr-3 pl-3"
            @click="onSubmitCSV"
          >
            {{ $t('messages.importCSV') }}</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
// Ultilites
  import moment from 'moment'
  import XLSX from 'xlsx'
  import { get } from 'vuex-pathify'
  import _ from 'lodash'

  export default {
    name: 'EmployeeList',
    data () {
      return {
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        excelFileConfig: {
          fileOffSet: 1, // defaut
          fileHeaderStart: 1,
          fileDataSkip: 1,
        },
        fileIsValid: false,
        rawData: [],
        previewOptions: [
          { text: this.$t('messages.all'), value: 'ALL' },
          { text: this.$t('messages.only_error'), value: 'ONLY_ERROR' },
          { text: this.$t('messages.non_error'), value: 'NON_ERROR' },
          { text: this.$t('messages.critical_error'), value: 'CRITICAL_ERROR' },
        ],
        filterSearch: '',
        headers: [{
                    text: this.$t('table.headers.index'),
                    align: 'center',
                    value: 'index',
                    class: 'font-weight-bold',
                    width: '8rem',
                  },
                  {
                    text: this.$t('table.headers.manageId'),
                    align: 'start',
                    value: 'manageId',
                    class: 'font-weight-bold',
                    width: '8rem',
                  },
                  {
                    // need to confirm
                    text: this.$t('table.headers.sankyouKumamotoNum'),
                    align: 'start',
                    value: 'sankyouKumamotoNum',
                    class: 'font-weight-bold',
                    width: '12rem',
                  },
                  {
                    text: this.$t('table.headers.serviceInfo'),
                    align: 'start',
                    value: 'serviceInfo',
                    class: 'font-weight-bold',
                    width: '12rem',
                  },
                  {
                    text: this.$t('table.headers.dob'),
                    align: 'start',
                    value: 'dob',
                    class: 'font-weight-bold',
                    width: '10rem',
                  },
                  {
                    text: this.$t('table.headers.name'),
                    align: 'start',
                    value: 'name',
                    class: 'font-weight-bold',
                    width: '14rem',
                  },
                  {
                    text: this.$t('table.headers.visa'),
                    align: 'start',
                    value: 'visa',
                    class: 'font-weight-bold',
                    width: '8rem',
                  },
                  {
                    text: this.$t('table.headers.furiganaName'),
                    align: 'start',
                    value: 'furiganaName',
                    class: 'font-weight-bold',
                    width: '14rem',
                  },
                  {
                    text: this.$t('table.headers.contactPhone'),
                    align: 'start',
                    value: 'contactPhone',
                    class: 'font-weight-bold',
                    width: '10rem',
                  },
                  {
                    text: this.$t('table.headers.age'),
                    align: 'start',
                    value: 'age',
                    class: 'font-weight-bold',
                    width: '6rem',
                  },
                  {
                    text: this.$t('table.headers.sex'),
                    align: 'start',
                    value: 'sex',
                    class: 'font-weight-bold',
                    width: '6rem',
                  },
                  {
                    text: this.$t('table.headers.language'),
                    align: 'start',
                    value: 'language',
                    class: 'font-weight-bold',
                    width: '8rem',
                  },
                  {
                    text: this.$t('table.headers.college_name'),
                    align: 'start',
                    value: 'collegeName',
                    class: 'font-weight-bold',
                    width: '10em',
                  },
                  {
                    text: this.$t('table.headers.student_card_valid'),
                    align: 'start',
                    value: 'campusCardValidDate',
                    class: 'font-weight-bold',
                    width: '13rem',
                  },
                  {
                    text: this.$t('table.headers.residence_card_id'),
                    align: 'start',
                    value: 'residenceCardId',
                    class: 'font-weight-bold',
                    width: '13rem',
                  },
                  {
                    text: this.$t('table.headers.residence_card_valided_to'),
                    align: 'start',
                    value: 'residenceCardValidDate',
                    class: 'font-weight-bold',
                    width: '15rem',
                  },
                  {
                    text: this.$t('table.headers.residence_card_valided_date_left'),
                    align: 'start',
                    value: 'residenceCardValidDateLeft',
                    class: 'font-weight-bold',
                    width: '14rem',
                  },
                  {
                    text: this.$t('table.headers.student_card_valided_date_left'),
                    align: 'start',
                    value: 'studentCardValidDateLeft',
                    class: 'font-weight-bold',
                    width: '14rem',
                  },
        ],
        items: [],
        fileCriticalErrors: [],
        file: [],
        loading: false,
        schema: [],
      }
    },
    computed: {
      ...get('parttimer', ['list', 'message', 'status', 'error']),
      schoolList: get('colleague@collegeList'),
      schoolStatus: get('colleague@status'),
    },
    watch: {
      schoolStatus (value) {
        this.loading = value === 'loading'
      },
      options: {
        handler () {
          this.initialize()
        },
        deep: true,
      },

      file (value) {
        this.filterSearch = ''
        this.rawData = ''
        this.doLoadAndPaser(value)
      },
      rawData (value) {
        if (value) {
          this.items = this.doCheckAndPreview(
            value,
            this.schema,
            this.excelFileConfig.fileOffSet,
          )
        }
      },
      items (value) {
        const tempCriticalErrors = []
        this.items.forEach(item => {
          // check if there is any error for required key
          if (item.errors.find(o => o.required === true)) {
            tempCriticalErrors.push(...item.errors)
          }
        })
        this.fileCriticalErrors = tempCriticalErrors
      },
      fileCriticalErrors (value) {
        this.fileIsValid = value.length === 0
      },
      status (value) {
        this.loading = value === 'loading'
      },
      message (value) {
        if (value === 'success') {
          this.$router.push({ path: '/parttimer-list', query: { success: true } })
        }
      },
      error (value) {
        this.showSnackBar(value)
      },
    },
    created () {
      this.$store.dispatch('colleague/getColleges')
    },
    mounted () {
      this.schema = [
        {
          key: 'name',
          headerName: 'Name',
          required: true,
          validate: value => {
            return !!value
          },
        },
        {
          key: 'sankyouKumamotoNum',
          headerName: '三協熊本No.',
        },
        { key: 'serviceInfo', headerName: '勤務先' },
        { key: 'manageId', unique: true, headerName: '社員No.', required: true },
        {
          key: 'contactPhone',
          headerName: '携帯番号',
          formatValue: fullWidthString => {
            return fullWidthString
              .replace(/[\uff01-\uff5e]/g, function (ch) {
                return String.fromCharCode(ch.charCodeAt(0) - 0xfee0)
              })
              .replace(/\u30FC/, '-')
          },
          validate: value => {
            if (!value) return true
            return /^(\d{3}[-－]\d{4}[-－]\d{4})$|^([０-９]{3}[-－][０-９]{4}[-－][０-９]{4})$|^([０-９]{11})$|^(\d{11})$/.test(
              value,
            )
          },

          defautValues: {
            無し: '',
            なし: '',
            ナシ: '',
          },
        },
        {
          key: 'dob',
          headerName: '生年月日',
          validate: value =>
            this.isValidDate(value) || /^$/.test(value),
          dateformat: 'YYYY-MM-DD',
        },
        {
          key: 'furiganaName',
          headerName: '名前',
        },
        {
          key: 'sex',
          headerName: '性別（Ⅿ・F）',
        },
        {
          key: 'language',
          required: true,
          headerName: '国籍',
          validate: value => {
            return !!value
          },
        },
        {
          key: 'visa',
          headerName: 'ビザ',
        },
        {
          key: 'collegeName',
          headerName: '学校名',
        },
        {
          key: 'campusCardValidDate',
          headerName: '学生証（有効期限）',

          validate: value =>
            /(\d{4}-\d{1,2}-\d{1,2})|(\d{1,2}\/\d{1,2}\/\d{4})|(\d{1,2}-\d{1,2}-\d{4})|(\d{4}\/\d{1,2}\/\d{1,2})/mu.test(
              value,
            ) || /^$/.test(value),
          dateformat: 'YYYY-MM-DD',
          defautValues: {
            無し: '',
            なし: '',
            ナシ: '',
            永住権: '永住権',
            家族滞在: '家族滞在',
            特定活動: '特定活動',
            卒業: '卒業',
          },
        },
        {
          key: 'residenceCardId',
          headerName: '在留カード番号',
        },
        {
          key: 'residenceCardValidDate',
          headerName: '在留カード（有効期限）',
          dateformat: 'YYYY-MM-DD',
          isNullIfError: true,
          defautValues: {
            無し: '',
            なし: '',
            ナシ: '',
          },
          validate: (value, row) => {
            if (value) {
              if (row[this.schema.find(o => o.key === 'residenceCardId')]) {
                return false
              }
              return /(\d{4}-\d{1,2}-\d{1,2})|(\d{1,2}\/\d{1,2}\/\d{4})|(\d{1,2}-\d{1,2}-\d{4})|(\d{4}\/\d{1,2}\/\d{1,2})/mu.test(
                value,
              )
            }
            return true
          },
        },
      ]
    },

    methods: {
      onSubmitCSV () {
        this.fileIsValid = true
        let tempData = []
        tempData = this.items.map(item => {
          // check if there is any error for required key
          if (item.errors.find(o => o.required === true)) {
            this.fileIsValid = false
          }

          // copy item to new item

          const newItem = { ...item }
          const collegeName = newItem.collegeName
          if (!newItem.sankyouKumamotoNum) newItem.sankyouKumamotoNum = null
          const school = this.schoolList.find(o => {
            return o.name === collegeName
          })
          newItem.collegeId = school ? school.id : null
          // only for testing
          newItem.sex =
            newItem.sex === 'F' ? false : newItem.sex === 'M' ? true : null
          // delete unnessary complete

          // update value for error key
          this.schema.forEach(elSchema => {
            const key = elSchema.key

            if (
              item.errors.find(o => {
                return o.key === key
              })
            ) {
              if (!item[key]) newItem[key] = ''
              else if (elSchema.defautValues) {
                if (elSchema.defautValues[item[key]]) {
                  newItem[key] = elSchema.defautValues[item[key]]
                } else {
                  newItem[key] = elSchema.isEmptyIfError
                    ? ''
                    : elSchema.isNullIfError
                      ? null
                      : item[key]
                }
              }
              newItem[key] = null
            } else if (elSchema.formatValue) {
              newItem[key] = elSchema.formatValue(item[key])
            }
          })
          delete newItem.collegeName
          delete newItem.errors
          delete newItem.residenceCardValidDateLeft
          delete newItem.studentCardValidDateLeft
          return newItem
        })

        if (this.fileIsValid) {
          this.$store.dispatch('parttimer/submitCSV', tempData)
        } else this.showSnackBar('messages.invalid_data')
      },
      initialize (search = {}) {},
      // Call paser function and load to Sate rawData
      doLoadAndPaser (file) {
        if (!file) return
        // set offset
        this.excelFileConfig.fileOffSet =
          this.excelFileConfig.fileOffSet +
          this.excelFileConfig.fileDataSkip +
          this.excelFileConfig.fileHeaderStart
        this.readData(file).then(response => {
          // only get first sheet in File
          // required state rawData !
          for (let num = 0; num <= this.excelFileConfig.fileDataSkip; num++) {
            response[0].length > 0 ? this.rawData = response[0] : this.rawData = response[num]
          }
        })
      },
      // check and preview the array of object through a schema
      doCheckAndPreview (data, schema, offset) {
        const items = data.map((row, index) => {
          const item = {}

          // validate and tranforming raw item
          const validate = (object, schema, rowIndex) =>
            schema
              .filter(col => {
                // transform item attribute
                const headerName = col.headerName
                const key = col.key
                item[key] = object[headerName]
                  ? object[headerName].replace(/\s+/g, ' ').trim()
                  : ''

                if (col.required && !object[headerName]) {
                  return true
                }
                if (col.unique) {
                  const testRecords = data.filter(o => {
                    return item[key] === o[headerName]
                  })
                  if (testRecords.length >= 2) return true
                }
                if (col.validate) {
                  return !col.validate(item[key], object)
                }
                return false
              })
              .map(col => {
                // set return error of item attribute
                return {
                  message: `row ${rowIndex} - ${object[col.headerName]} - '${
                    col.key
                  }'' is invalid.`,
                  key: col.key,
                  required: col.required,
                }
              })

          const errors = validate(row, schema, index + offset)

          item.errors = errors
          return item
        })

        return items
      },
      readData (fileName) {
        return new Promise((resolve, reject) => {
          var reader = new FileReader()
          reader.onload = e => {
            var data = e.target.result
            let returnData = []
            var workbook = XLSX.read(data, {
              type: 'binary',
            })

            workbook.SheetNames.forEach(sheetName => {
              // Here is your object
              var xlRowObject = XLSX.utils.sheet_to_json(
                workbook.Sheets[sheetName],
                {
                  defval: '',
                  range: this.excelFileConfig.fileHeaderStart,
                  raw: false,
                },
              )
              returnData = [...returnData, xlRowObject]
            })
            resolve(returnData)
          }

          reader.onerror = function (ex) {
            reject(ex)
          }

          reader.readAsBinaryString(fileName)
        })
      },
      setHighlight (item, fieldname) {
        const error = item.errors.find(o => o.key === fieldname)
        if (error) {
          if (item.errors.length === 0) return ''
          if (error.required) {
            return 'pl-2 d-flex h-100 error white--text justify-start align-center'
          }

          return 'pl-2 d-flex h-100 warning white--text justify-start align-center'
        }
        return ''
      },
      residenceCardValidDateLeft: function (value) {
        if (
          !value.residenceCardValidDate ||
          !moment(value.residenceCardValidDate).isValid()
        ) {
          return ''
        }
        const residenceCardValidDateLeft =
          -1 * moment().diff(moment(value.residenceCardValidDate), 'days')
        value.residenceCardValidDateLeft = residenceCardValidDateLeft
        return residenceCardValidDateLeft
      },
      studentCardValidDateLeft: function (value) {
        if (
          !value.campusCardValidDate ||
          !moment(value.campusCardValidDate).isValid()
        ) {
          return ''
        }

        const studentCardValidDateLeft =
          -1 * moment().diff(moment(value.campusCardValidDate), 'days')
        value.studentCardValidDateLeft = studentCardValidDateLeft
        return studentCardValidDateLeft
      },
      filterByError (value, search, item) {
        if (search) {
          if (search === 'ALL') return true
          if (search === 'ONLY_ERROR') return item.errors.length > 0
          if (search === 'NON_ERROR') return item.errors.length === 0
          if (search === 'CRITICAL_ERROR') {
            return item.errors.find(o => {
              return o.required === true
            })
          }
        }
      },
      getAge (dob) {
        const age = moment().diff(dob, 'years')
        if (isNaN(age) || age < 0) return ''
        return age
      },
      isValidDate (dateString) {
        const regEx = /^\d{4}-\d{2}-\d{2}$/
        if (!dateString.match(regEx)) return false
        const d = new Date(dateString)
        const dNum = d.getTime()
        if (!dNum && dNum !== 0) return false // NaN value, Invalid date
        return d.toISOString().slice(0, 10) === dateString
      },
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
    },
  }
</script>
<style lang="css">
.v-data-table {
  max-width: 100%;
}
</style>
